<template>
  <el-dialog
    :title="titleDialog"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="50%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form
        ref="refForm"
        :model="formData"
        label-width="150px"
        :rules="formRules"
        :loading="formLoading"
      >
        <el-form-item label="扣款类型：" prop="deduction_type">
          <el-select
            v-model="formData.deduction_type"
            placeholder="选择扣款类型"
            :disabled="disabledData"
            clearable
          >
            <el-option
              v-for="item of deductionTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="供应商：" prop="supplier_id">
          <el-select
            v-model="formData.supplier_id"
            :loading="loadingSupplierList"
            :disabled="disabledData"
            filterable
            clearable
            placeholder="选择供应商"
          >
            <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="`${item.title}【${item.mobile}】`"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="requiredAsNo" label="售后单号：" prop="as_no">
          <el-input
            v-model="formData.as_no"
            :disabled="disabledData"
            placeholder="输入售后单号"
            clearable
          >
          </el-input>
        </el-form-item>

        <p class="tooltip" v-if="!disabledData">
          扣款类型为二次售后扣款且关联售后单号后将给用户线上退款，请谨慎操作
        </p>

        <el-form-item label="金额：" prop="deduction_amt">
          <el-input
            v-model="formData.deduction_amt"
            :disabled="disabledData"
            placeholder="输入金额"
            clearable
          >
          </el-input>
        </el-form-item>

        <el-form-item label="备注：" prop="remarks">
          <el-input
            type="textarea"
            v-model="formData.remarks"
            placeholder="输入备注"
            maxlength="200"
            :disabled="disabledData"
            :rows="3"
            show-word-limit
          >
          </el-input>
        </el-form-item>

        <el-form-item label="相关证明：" prop="pic">
          <b-file-upload
            :accepts="['jpg', 'jpeg', 'png']"
            :limit="5"
            :disabled="disabledData"
            v-model="picList"
          ></b-file-upload>
        </el-form-item>

        <el-form-item label="上传视频：">
          <b-file-upload
            :accepts="['mp4', 'avi', 'flv', 'mov']"
            :limit="1"
            :disabled="disabledData"
            v-model="videoList"
          ></b-file-upload>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="!disabledData" @click="onHandleClose">取消</el-button>
      <el-button
        type="primary"
        @click="onHandleSubmit"
        :disabled="disabledSubmit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import BFileUpload from "@/components/business/BFileUpload/index.vue";
import { validMoney } from "@/utils/tools/validate";
import {
  getSupplierSelectList,
  postSupplierDeductionSave,
} from "@/api/general/operation-center/index";
import { DEDUCTION_TYPE_ENUM } from "../../utils/enum/index";
// import { formatDate, toDate } from "@/utils/tools/date.js";

export default {
  name: "EditDataDialog",
  components: {
    BFileUpload,
  },
  props: {
    /**
     * 扣款类型下拉
     */
    deductionTypeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      loadingSupplierList: false,
      loadingSelfTakeList: false,
      disabledSubmit: false,
      disabledData: false,
      formLoading: false,
      titleDialog: "新增",
      supplierList: [], // 供应商列表
      picList: [], // 图片列表
      videoList: [], // 视频列表
      formData: {
        deduction_type: "", // 扣款类型
        supplier_id: "", // 供应商ID
        as_no: "", // 售后单号
        deduction_amt: "", // 扣款金额
        remarks: "", // 备注
        pic: [], // 扣款证明
        video: "", // 视频
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      formRules: {
        deduction_type: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请选择扣款类型！",
          },
        ],
        supplier_id: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请选择供应商！",
          },
        ],
        as_no: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!value && this.requiredAsNo) {
                callback(new Error("请输入售后单号！"));
              }
              callback();
            },
          },
        ],
        deduction_amt: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入金额！"));
              }
              if (!Number(value) || !validMoney(value)) {
                callback(new Error("大于0，允许两位小数点"));
              }
              callback();
            },
          },
        ],
        remarks: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请输入备注！",
          },
        ],
        pic: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!this.picList.length) {
                callback(new Error("请上传图片！"));
              }
              callback();
            },
          },
        ],
      },
    };
  },
  computed: {
    /**
     * 当为二次扣款 | 线下售后扣款时, 则为必填
     */
    requiredAsNo() {
      let res = false;
      if (
        this.formData.deduction_type === DEDUCTION_TYPE_ENUM.COMMON.value ||
        this.formData.deduction_type === DEDUCTION_TYPE_ENUM.OFFLINE.value
      ) {
        res = true;
      }
      return res;
    },
  },
  watch: {
    /**
     * 图片发生改变
     */
    picList() {
      const res = this.picList?.map((item) => item.url);
      this.formData.pic = res;
    },
    /**
     * 视频发生改变
     */
    videoList() {
      const res = this.videoList?.map((item) => item.url);
      this.formData.video = res;
    },
  },
  methods: {
    /**
     * 初始化
     */
    async onInitData(data) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.clearValidate();
      this.ajaxGetSupplierSelectList();
      data && this.setEditData(data);
    },
    /**
     * 获取供应商下拉列
     */
    async ajaxGetSupplierSelectList() {
      this.loadingSupplierList = true;
      try {
        const { data } = await getSupplierSelectList();
        this.supplierList = data;
      } catch (err) {
        console.log("ajax getSupplierSelectList err", err);
      } finally {
        this.loadingSupplierList = false;
      }
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.dataTime = [];
      this.picList = [];
      this.videoList = [];
      this.titleDialog = "新增";
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
      this.disabledData = false;
    },
    /**
     * 回显数据
     */
    setEditData(data) {
      this.formLoading = true;
      if (data.type === "view") {
        this.titleDialog = "查看";
        this.disabledData = true;
      }
      const row = data.row;
      Object.keys(row).forEach((_KEY) => {
        this.formData[_KEY] = row[_KEY];
      });
      this.formData.pic = row.img_list || [];
      const fileList =
        row.img_list?.map((child) => {
          return {
            url: child,
            name: child,
          };
        }) || [];
      this.picList = fileList;
      // 更改回显
      const fileList1 =
        row.video_list?.map((vItem) => {
          return {
            url: vItem,
            name: vItem,
          };
        }) || [];
      this.videoList = fileList1;
      this.formLoading = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      // 如果为只读, 则直接关闭
      if (this.disabledData) {
        this.onHandleClose();
        return;
      }
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.ajaxPostUpdateData();
      });
    },
    /**
     * 提交
     */
    async ajaxPostUpdateData() {
      const params = {
        ...this.formData,
      };
      // 当扣款为售后类型，则删除掉售后单号
      if (this.formData.deduction_type === DEDUCTION_TYPE_ENUM.OTHER.value) {
        delete params.as_no;
      }
      this.disabledSubmit = true;
      try {
        await postSupplierDeductionSave(params);
        this.$message.success("操作成功");
        this.$emit("on-change");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax postSupplierDeductionSave err", err);
      } finally {
        this.disabledSubmit = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  /deep/ .el-form {
    margin: 0 50px;
    .el-textarea {
      .el-input__count {
        bottom: -5px;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .el-input,
  .el-select,
  .el-textarea {
    width: 400px;
  }
  .tooltip {
    position: relative;
    left: 13%;
    margin: 20px 0;
    color: $danger-color;
  }
}
</style>
