/** 扣款类型 */
export const DEDUCTION_TYPE_ENUM = {
  COMMON: {
    value: 40,
    label: "二次发起售后",
  },
  OTHER: {
    value: 50,
    label: "其他扣款",
  },
  OFFLINE: {
    value: 60,
    label: "线下售后扣款",
  },
};

/** 审核状态 */
export const CHECK_STATUS = {
  NO: {
    value: 0,
    label: "未审核",
  },
  YES: {
    value: 1,
    label: "已审核",
  },
  INVALID: {
    value: 2,
    label: "作废",
  },
};

/** 扣款状态 */
export const DEDUCTION_STATUS = {
  NO: {
    value: 0,
    label: "未扣款",
  },
  YES: {
    value: 1,
    label: "已扣款",
  },
};
